img {
	max-width: 100%;
	height: auto;
}

.container-max-width {
	max-width: $container-max-width;
  @include media-breakpoint-up(md) {
    padding-right: ($grid-gutter-width * 1.2);
    padding-left: ($grid-gutter-width * 1.2);
  }
  @include media-breakpoint-up(xl) {
    padding-right: ($grid-gutter-width * 1.3);
    padding-left: ($grid-gutter-width * 1.3);
  }
}


%clearfix {
  *zoom: 1;

  &::after,
  &::before {
    content: " ";
    display: table;
  }

  &::after {
    clear: both;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

img[data-sizes="auto"] {
  display: block; 
  width: 100%;
}

form {
  margin: 2rem 0;
  max-width: 50rem;
}

.form-control {
  border-bottom: 1px solid $primary;
}
