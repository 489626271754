 /* loading option */
.animsition-loading,
.animsition-loading:after {
  width: 48px;
  height: 48px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -24px;
  margin-left: -24px;
  border-radius: 50%;
  z-index: 500;
}

.animsition-loading {
  background-color: transparent;
  border-top: 3px solid $gray-200;
  border-right:3px solid $gray-200;
  border-bottom: 3px solid $gray-200;
  border-left: 3px solid $primary;
  transform: translateZ(0);
  animation-iteration-count:infinite;
  animation-timing-function: linear;
  animation-duration: .8s;
  animation-name: animsition-loading;
}

@keyframes animsition-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
