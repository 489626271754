article.search-article {
  margin-bottom: 1.8rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.1rem;
  }
  a {
    color: $body-color;

    &:hover {
      color: $primary;
    }
  }
}