.lightbox {
  position: relative;
  overflow: hidden;
  
  a {
    overflow: hidden;

    img {
      transition: transform 0.3s;
    }
    &:hover {
      text-decoration: none;

      img {
        transform: scale(1.05);
      }
    }
    &::before {
      @include font-awesome-as-content;
      content: "\f00e";
      position: absolute;
      color: $primary;
      bottom: 4px;
      left: 8px;
      font-size: 1.1rem;
      z-index: 99;
    }
  }
}