.team-container {
  section {
    margin-bottom: 3rem;;
  }
  .section--title {
    border-bottom: 1px solid $primary;
    h3 {
      background-color: $primary;
      color: white;
      font-size: 1.2rem;
      margin: 0;
      padding: 3px 15px;
      display: inline-block;
      min-width: 33.3333%;
    }
  }
}

.team-container .team-member {
  margin-top: 8px;
  
  @include media-breakpoint-up(sm) {
    margin-top: 15px;
  }

  .team-member--photo {
    background-color: red;
    background-size: cover;
    background-position: center center;
    border-right: 4px solid $primary;

    @include media-breakpoint-down(sm) {
      height: 0;
      padding-bottom: 62%;
      border-right: none;
      border-bottom: 4px solid $primary;
    }
  }

  .team-member--info {
    padding-top: 0.7rem;
    padding-bottom: 1.8rem;

    a {
      color: $body-color;
      display: inline-block;
      margin-right: 10px;
      
      @include media-breakpoint-up(md) {
        margin-right: 0;
        display: table;
      }

      &:hover {
        color: $primary;  
      }
    }

    h4 {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }

    .team-member--position {
      margin-bottom: 5px;
    }

    .team-member--mobil::before,
    .team-member--phone::before,
    .team-member--email::before {
      @include font-awesome-as-content;
      color: $primary;
      margin-right: 5px;
      display: inline-block;
      width: 17px;
      text-align: center;
    }

    .team-member--mobil::before {
      content: "\f3cd";
    }

    .team-member--email::before {
      content: "\f0e0";
    }

    .team-member--phone::before {
      content: "\f095";
    }

  }
}