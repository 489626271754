.loading-spinner {
  width: 33px;
  height: 30px;
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  animation: rotateSpinner 3s ease infinite;

  &::before,
  &::after {
    position: absolute;
    top: 0;
  }
  &::before {
    @include triangle(topleft, $primary, 30px);
    left: 0;
  }
  &::after {
    @include triangle(bottomright, $gray-200, 30px);
    right: 0;
  }
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0);
    width: 36px;
  }
  25% {
    width: 52px;
  }
  50% {
    transform: rotate(180deg);
    width: 36px;
  }
  75% {
    width: 52px;
  }
  100% {
    width: 36px;
    transform: rotate(360deg);
  }
}