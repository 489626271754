ul.download-list {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;
    position: relative;

    a {
      display: block;
      background-color: $gray-100;
      padding: 0.45rem 1rem;
      padding-right: 2.5rem;
      transition: background-color 0.3s;
      color: $body-color;
      line-height: 1.4;

      &:hover {
        background-color: $gray-200;
        color: $link-color;
      }

      &::before {
        @include font-awesome-as-content;
        content: "\f56d";
        position: absolute;
        top: 4px;
        right: 1rem;
        font-size: 1rem;
        z-index: 99;
      }
    }
  }
}