aside.sidebar {
  section {
    margin-bottom: 2rem;
  }
  .title {
    color: $primary;
    margin-bottom: 0.55rem;
  }

  ul.nav {
    li {
      margin-bottom: 0.3rem;
      
      @include media-breakpoint-down(xs) {
        margin-right: 0.5rem;
        &::after {
          content: "|";
          color: $gray-300;
          margin-left: 0.5rem;
        }

        &:last-child::after {
          display: none;
        }
    }

      a {
        color: $body-color;

        &:hover {
          color: $link-color;
          text-decoration: none;
        }
      }

      &.active a {
        color: $primary;
      }
    }
  }
  .sidebar--search {
    max-width: 12rem;

    input {
      border: none;
      border-bottom: 1px solid $gray-600;
    }

    button {
      border: none;
      color: $primary;
      &:hover {
        background-color: $gray-200;
      }
    }
  }
}