header .main-slider {
  .slider-item {
    
    .slider-item-responsive {
      position: relative;
      padding-bottom: 60%;
      height: 0;
      
      @include media-breakpoint-up(md) {
        padding-bottom: 38.5%;
      }

      @include media-breakpoint-up(xl) {
        padding-bottom: 40%;
      }
    }
    .slider-item--bg {
      background-size: cover;
      background-position: top center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &::after {
        content: "";
        position: absolute;
        top: 70%;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
        opacity: 0.5;
      }
    }
  }
}

header {
  padding-top: 20px;
  @include breakpoint {
    padding-top: 32px;
  }
}

