footer#footer {
  background-color: $gray-100;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  line-height: $line-height-base * 1.11;
  
  @include media-breakpoint-up(md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  a {
    color: inherit;
  }
  
  i {
    font-size: 0.8rem;
    color: $link-color;
  }
  
  .container-fluid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .footer-logo,
  .footer-nav,
  .footer-info {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .footer-logo {
    img {
      width: 220px;
      padding-right: 1.5rem;
    }
  }

  .footer-nav {
    a {
      display: block;
      font-weight: $font-weight-bold;
    }
  }

  .footer-info {
    a {
      display: inline-block;
    }
    
    @include media-breakpoint-up(lg) {
      text-align: right;
    }
  }
}