// scroll up
#scrollUp {
  bottom: 50px;
  right: 10px;
  height: 38px;
  width: 38px;
  background-color: $primary;
  border-bottom: 0;
  text-align: center;
  color: #fff;

  @include media-breakpoint-up(lg) {
    bottom: 55px;
    right: 15px;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &::before {
    @include font-awesome-as-content;
    position: absolute;
    color: white;
    top: 8px;
    right: 0;
    left: 0;
    font-size: 12px;
    content: "\f077";
  }
}

// scroll down
#scrollDown {
  bottom: 10px;
  right: 10px;
  height: 38px;
  width: 38px;
  background-color: $primary;
  border-bottom: 0;
  text-align: center;
  color: #fff;
  position: fixed;
  z-index: 13456;
  cursor: pointer;

  @include media-breakpoint-up(lg) {
    bottom: 15px;
    right: 15px;
  }

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }

  &::before {
    @include font-awesome-as-content;
    position: absolute;
    color: white;
    top: 8px;
    right: 0;
    left: 0;
    font-size: 12px;
    content: "\f078";
  }
}
