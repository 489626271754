.topnavbar {
  position: absolute;
  width: 100%;
  padding-top: 1rem;
  //background-color: rgba($color: $white, $alpha: 0.8);
  background: linear-gradient(to bottom, rgba(255,255,255,1) 30%,rgba(255,255,255,0.8) 100%);
  z-index: 100;

  @include breakpoint {
    padding-top: 2.1rem;
    position: fixed;
    transition: all 0.3s;

    &.nav-shrink {
      transform: translateY(-12px);
      background-color: rgba($color: $white, $alpha: 1);
      border-bottom: 1px solid rgba(black, 0.15);
    }

  }

  body.no-header & {
    position: relative;
    border-bottom: 1px solid rgba($black, .1);

    &.nav-shrink {
      transform: none;
    }
  }

  .container-fluid {
    justify-content: space-between;
    display: flex;
    align-items: flex-end;
  }
}

.topnavbar .logo {
  width: 160px;
  padding-bottom: 0.65rem;
  order: 2;
  @include breakpoint {
    width: 230px;
    padding-bottom: 1.2rem;
    order: 0;
  }
}

.topnavbar nav {
  display: none;
  visibility: hidden;
  text-transform: uppercase;
  font-weight: $font-weight-bold;

  
  a {
    color: $body-color;
    margin: 0 6px;
    
    &:hover {
      text-decoration: none;
      color: $link-color;
    }
    
    &.active {
      color: $link-color;
    }
  }

  ul.nav > li {
    padding-bottom: 1rem;
    position: relative;
  }
}

// Subnav
.topnavbar {
  ul.nav {
    li:hover {
      ul.subnav {
        opacity: 1;
        transition: opacity 0.3s, transform 0.35s;
        left: 50%;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  ul.subnav {
    text-transform: none;
    font-weight: $font-weight-normal;
    top: 100%;
    left: -99999em;
    transform: translateX(-50%) translateY(15px);
    opacity: 0;
    transition: all 0.3s;
    list-style: none;
    margin: 0;
    padding: 1.2rem 1.6rem;
    position: absolute;
    white-space: nowrap;
    border-top: 3px solid $primary;
    background-color: $gray-100;

    li {
      margin: 8px 0;
    }

    &::before {
      @include triangle(top, $primary, 12px);
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
    }
  }
}

// Menu Icon
.menu-icon {
  display: block;
  position: relative;
  cursor: pointer;
  z-index: 999;
  top: -1.1rem;
  color: $primary;
  letter-spacing: 0.15em;
  font-size: 14px;
  transform: translateZ(0);
  height: 24px;
  width: 24px;

  &:hover .box-shadow-menu::before {
    background: $gray-900;
    box-shadow: 0 6px 0 0 $gray-900, 0 12px 0 0 $gray-900;
  }
}

.box-shadow-menu {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    width: 20px;
    height: 2px;
    background: $primary;
    z-index: 999;
    box-shadow: 0 6px 0 0 $primary, 0 12px 0 0 $primary;
  }
}

@include breakpoint {
  .navbar-menu-icon {
    display: none;
    visibility: hidden;
  }
  .topnavbar nav {
    display: block;
    visibility: visible;
  }
}