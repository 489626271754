article.news-article {
  margin-bottom: 1.3rem;
  //padding-bottom: 1.8rem;
  //border-bottom: 1px solid $gray-100;
  padding: 1.1rem;
  background-color: $gray-100;
  display: flex;
  flex-direction: column;
  @include clearfix;

  &.detail {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
  }

  &:last-child {
    border: none;
  }

  .news-article--img {
    order: 2;
    margin: 0.5rem 0 1rem;
    border-left: 4px solid $primary;
    max-width: 80%;
  }

  .news-article--title {
    order: 1;
  }

  .news-article--text {
    order: 3;
  }

  @include media-breakpoint-up(lg) {
    display: block;
    margin-bottom: 1.8rem;
    padding: 1.8rem;
    //padding-bottom: 2.1rem;
    
    .news-article--img {
      max-width: 17rem;
      float: right;
      margin: 0.5rem 0 1rem 1rem;
    }
  }
}