
body {
  @include antialiased;
}

//* Responsive font size
html {
  font-size: 0.8rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 0.92rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    font-size: 1rem;
  }
}

main article ul {
  padding: 0;
  padding-left: 1em;
  list-style-type: square;
}

