#projects {
  border-top: 1px solid $gray-200;
  @include sectionPadding;
}

.projects-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 2.5rem;

  &::after {
    content: "";
    flex: auto;
  }

  .close {
    color: $primary;
    top: 15px;
    right: 20px;
    opacity: 1;
    position: absolute;
    font-size: 1.8rem;
  }

  .projects-item {
    margin-bottom: 1px;
    width:  50%;
    
    @include media-breakpoint-up(md) {
      width:  25%;
    }

    &.active {
      .arrow {
        @include triangle(top, $primary, 16px);
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        background-color: transparent;
        opacity: 1;
        top: auto;
      }
    }
    
    .projects-item--bg {
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      padding-bottom: 70%;
      width: 100%;
      height: 0;
      position: relative;
      outline: 1px solid rgba(white, 0.5);

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0.5px;
        bottom: 0.5px;
        left: 0.5px;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
        opacity: 0.9;
      }
      
      &::before {
        content: "";
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: rgba($color: $primary, $alpha: 0.8);
        z-index: 1;
        opacity: 0;
        transition: all 0.3s;
      }
    }

    .projects-item--title {
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: $white;
      font-weight: $font-weight-bold;
      text-align: right;
      font-size: 1.15rem;
      line-height: 1.2;
      z-index: 99;
      transition: all 0.3s;
    }

    .projects-item--content {
      position: absolute;
      left: 0;
      right: 0;
      @include sectionPadding;
      border-top: 5px solid $primary;
      z-index: 99;
      margin-top: -2px;

      h3 {
        color: $primary;
      }

      .projects-item--gallery {
        [class*="col-"] {
          border: 8px solid white;
        }
      }
    }
  }
}

// Hover effects
.projects-container .projects-item--bg:hover {
  cursor: pointer;

  &::before {
    opacity: 0.9;
  }

  .projects-item--title {
    bottom: 40px;
  }
}



#projects-page .projects-container .projects-item {
  width: 50%;

  @include media-breakpoint-up(lg) {
    width: 33.33333333%;
  }
}