.animsition,
.animsition-overlay {
  position: relative;
  opacity: 0;
  animation-fill-mode: both;
}

/**
 * overlay option
 */
.animsition-overlay-slide {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ddd;
}
